@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Poppins:100,200,300,400,500,600,700,800,900&display=swap);
@import './assets/css/tailwind.output.css';
/* @import '~animate.css/animate.css'; */

html,
body {
  height: 100%;
  margin: 0;
}
.wrapper {
  min-height: 100%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -100px;
}
footer,
.push {
  height: 100px;
}

:root {
  --font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

/* Fix bootstrap - AntD */
.anticon svg {
  vertical-align: 0rem !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.ant-layout-content {
  margin: 0 !important;
}

/*
 * Add .ant-header-responsive for page header
 */
@media (max-width: 576px) {
  .ant-header-responsive .ant-page-header-heading-extra {
    white-space: normal;
  }
  .ant-header-responsive .ant-page-header-heading-extra > * {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
  }

  .ant-header-responsive .ant-page-header-heading-extra > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:last-child {
    display: none;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}

/* @media (min-width: 992px) {
  .ant-layout-sider.ant-layout-sider-light,
  .ant-layout-sider.ant-layout-sider-dark {
    min-height: 100vh !important;
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    width: 300px !important;
  }
}

@media (min-width: 1200px) {
  .ant-layout-sider.ant-layout-sider-light,
  .ant-layout-sider.ant-layout-sider-dark {
    min-height: 100vh !important;
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }
}

@media (min-width: 1600px) {
  .ant-layout-sider.ant-layout-sider-light,
  .ant-layout-sider.ant-layout-sider-dark {
    min-height: 100vh !important;
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    width: 300px !important;
  }
} */

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .only-print {
    display: inline !important;
  }
}

@media all {
  .page-break {
    display: none;
  }

  .only-print {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  #order-detail > div {
    padding: 0px !important;
  }
}

@page {
  size: auto;
  margin: 1.5cm;
}

/* Disable google maps info window close */
button[title='Close'].gm-ui-hover-effect {
  display: none !important;
}
button[title='Cerrar'].gm-ui-hover-effect {
  display: none !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.ant-table-row.danger .ant-table-cell {
  background: #d22f260d !important;
}

.ant-table-row.danger .ant-table-cell .delivery-time-text {
  color: #d22f26 !important;
}
