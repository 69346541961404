@import '~antd/es/style/themes/default.less';

.pie {
  position: relative;
  .chart {
    position: relative;
  }
  &.hasLegend .chart {
    width: ~'calc(100% - 240px)';
  }
  .legend {
    position: absolute;
    top: 50%;
    right: 0;
    min-width: 200px;
    margin: 0 20px;
    padding: 0;
    list-style: none;
    transform: translateY(-50%);
    li {
      height: 22px;
      margin-bottom: 16px;
      line-height: 22px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 8px;
  }
  .line {
    display: inline-block;
    width: 1px;
    height: 16px;
    margin-right: 8px;
    background-color: @border-color-split;
  }
  .legendTitle {
    color: @text-color;
  }
  .percent {
    color: @text-color-secondary;
  }
  .value {
    position: absolute;
    right: 0;
  }
  .title {
    margin-bottom: 8px;
  }
  .total {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 62px;
    text-align: center;
    transform: translate(-50%, -50%);
    & > h4 {
      height: 22px;
      margin-bottom: 8px;
      color: @text-color-secondary;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
    }
    & > p {
      display: block;
      height: 32px;
      color: @heading-color;
      font-size: 1.2em;
      line-height: 32px;
      white-space: nowrap;
    }
  }
}

.legendBlock {
  &.hasLegend .chart {
    width: 100%;
    margin: 0 0 32px 0;
  }
  .legend {
    position: relative;
    transform: none;
  }
}
