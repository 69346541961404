@import '~antd/es/style/themes/default.less';

.field {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .label,
  .number {
    font-size: @font-size-base;
    line-height: 22px;
  }
  .number {
    margin-left: 8px;
    color: @heading-color;
  }
}
