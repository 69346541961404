@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap');

.ant-card {
  border-radius: 6px !important;
  -webkit-box-shadow: 5px 5px 30px 10px rgba(153, 153, 153, 0.2) !important;
  -moz-box-shadow: 5px 5px 30px 10px rgba(153, 153, 153, 0.2) !important;
  box-shadow: 5px 5px 30px 10px rgba(153, 153, 153, 0.2) !important;
}

.bg-clear-gray {
  background-color: #edf2f9 !important;
}

@font-face {
  font-family: Gilroy;
  src: url(./assets/fonts/Gilroy/Gilroy-Regular.ttf);
}

@font-face {
  font-family: Gilroy;
  src: url(./assets/fonts/Gilroy/Gilroy-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Gilroy;
  src: url(./assets/fonts/Gilroy/Gilroy-Medium.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Gilroy;
  src: url(./assets/fonts/Gilroy/Gilroy-Bold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Gilroy;
  src: url(./assets/fonts/Gilroy/Gilroy-Heavy.ttf);
  font-weight: 700;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}